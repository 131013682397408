import { useEffect, useState } from 'preact/hooks';
import TopBar from './components/topbar';
import './style';
import { getServerURL } from './util';
import CarousalSection from './components/carousalsections';
import Footer from './components/footer';

function fetchAppData( languageid, onsuccess, onfailure ) {
	let url = getServerURL() + "/v1/appdata";
	if( languageid ) {
		url += "?language=" + languageid
	}
	fetch( url )
		.then( res => {
			if( !res.ok ) {
				let err = new Error("HTTP status code: " + res.status)
				err.res = res;
				err.status = res.status;
				throw err
			}
			return res.json()
		})
		.then( onsuccess )
		.catch( onfailure )
}

let categoryStore = [];
let imageStore = [];

export default function App() {
	const [ languages, setLanguages ] = useState([]);
	const [ images, setImageList ] = useState([]);
	const [ categories, setCategoryList ] = useState([]);
	const [ currentLanguage, setCurrentLanguage ] = useState("English");

	useEffect(() => {
		let languagename = null;
		let hash = document.location.hash;
		if( hash ) {
			languagename = hash.substring( 1 );
		}
        fetchAppData( null, ( resp ) => {
			if( resp && resp.data ) {
				let languages = resp.data.languages;
				let images = resp.data.images;
				let categories = resp.data.categories;

				categoryStore = categories;
				imageStore = images;
				
				setLanguages( languages );
				setImageList( images );
				setCategoryList( categories );
				if( languagename ) {
					let selectedlang = languages.find( lang => {
						return lang.name.toLowerCase() == languagename.toLowerCase()
					})
					setCurrentLanguage( selectedlang || languages[ 0 ] );
				} else {
					setCurrentLanguage( languages[0] );
				}
			}
		}, ( error ) => {
			console.log( error )
		})
    }, [])

	useEffect(() => {
		let sendRequest = false;
		let allowedcategories = categoryStore.filter( category => {
			return category.language == currentLanguage.id
		})
		let allowedimages = imageStore.filter( image => {
			return image.language == currentLanguage.id
		})
		if( allowedcategories.length > 0 ) {
			setCategoryList( allowedcategories );
			setImageList( allowedimages );
		} else {
			sendRequest = true;
		}
		if( sendRequest ) {
			fetchAppData( currentLanguage?.id, ( resp ) => {
				if( resp && resp.data ) {
					let images = resp.data.images;
					let categories = resp.data.categories;

					setImageList( images );
					setCategoryList( categories );
				}
			}, ( error ) => {
				console.log( error )
			})
		}
	}, [ currentLanguage ])
	return (
		<div>
			<TopBar
				languages={languages}
				categories={categories}
				currentLanguage={currentLanguage}
				setCurrentLanguage={setCurrentLanguage}
			/>
			{
				categories.map( category => {
					return (
						<CarousalSection
							category={category}
							images={images}
							language={currentLanguage}
						/>
					)
				})
			}
			<Footer />
		</div>
	);
}

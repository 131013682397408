import '../style.css';

export default function Footer() {
    let year = new Date().getFullYear();
    return (
        <footer class="footer">
            <div>
                All rights reserved by Media12D &copy; {year} - 
                To Contact <a class="contactmail" href="mailto:mediatwelved@gmail.com">mediatwelved@gmail.com</a>
            </div>
        </footer>
    )
}
import '../style.css';
import "../../node_modules/@glidejs/glide/dist/css/glide.core.min.css";
import Glide from '@glidejs/glide'
import { useEffect, useRef } from 'preact/hooks';
import { getServerURL } from '../util';

export default function CarousalSection({ category, images, language }) {
    const imagelist = images.filter( image => {
        return image.language == language.id && image.category == category.id
    })
    if( imagelist.length == 0 ) {
        return null;
    }
    const carousalRef = useRef(null);
    function mountGlide(){
        if( carousalRef && carousalRef.current ) {
            try {
                new Glide( carousalRef.current, {
                    type: 'carousel',
                    startAt: 0,
                    perView: 6,
                    autoplay: 3000,
                    hoverpause: true,
                    breakpoints: {
                        1300: {
                            perView: 5
                        },
                        1000: {
                            perView: 4
                        },
                        800: {
                            perView: 3
                        },
                        600: {
                            perView: 2
                        },
                        400: {
                            perView: 1
                        }
                    }
                } ).mount();
            } catch ( e ) {

            }
        }
    }
    useEffect(() => {
        mountGlide()
    }, [])
    useEffect(() => {
        mountGlide()
    }, [ language ])
    return (
        <div class="carousal_section">
            <h2 classs="category" id={category.name}> { category.name } </h2>
            <div class="glide" ref={carousalRef}>
                <div class="glide__track" data-glide-el="track">
                    <ul class="glide__slides">
                        {
                            imagelist.map( image => {
                                return (
                                    <li class="glide__slide">
                                        <img
                                            src={ getServerURL() + "/" + image.url }
                                            class="slider__image"
                                        />
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                <div class="glide__arrows" data-glide-el="controls">
                    <button class="glide__arrow glide__arrow--left" data-glide-dir="<">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="m12.718 4.707-1.413-1.415L2.585 12l8.72 8.707 1.413-1.415L6.417 13H20v-2H6.416l6.302-6.293z"/></svg>
                    </button>
                    <button class="glide__arrow glide__arrow--right" data-glide-dir=">">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M11.293 4.707 17.586 11H4v2h13.586l-6.293 6.293 1.414 1.414L21.414 12l-8.707-8.707-1.414 1.414z"/></svg>
                    </button>
                </div>
            </div>

        </div>
    )
}
import '../style.css';
import { useState } from "preact/hooks";
import ClickOutside from './clickoutside';

export default function TopBar({ languages, categories, currentLanguage, setCurrentLanguage }) {
    const [ isDropdownOpen, setDropdownstate ] = useState(false);
    const [ isCategoriesDropdownOpen, setCategoriesDropdownOpen ] = useState( false );
    let dropdown = null;
    if( isDropdownOpen ) {
        dropdown = (
            <ClickOutside
                onClickOutside={( ev ) => {
                    setDropdownstate(false)
                }}
                className="drowdownlist"
            >
                <div >
                    {
                        languages ? languages.map(( lang ) => {
                            return (
                                <div
                                    class="language"
                                    onclick={()=>setCurrentLanguage( lang )}
                                    key={lang.id}
                                >
                                    {lang.name}
                                </div>
                            )
                        }) : null
                    }
                </div>
            </ClickOutside>
        )
    }
    let categoryselector = categories.map( category => {
        return (
            <div onclick={() => {
                let titleelem = document.querySelector( "#" + category.name );
                titleelem && titleelem.scrollIntoView();
            }}
            key={category.id}>
                { category.name }
            </div>
        )
    } );
    if( typeof window !== "undefined" ) {
        if( window.innerWidth < 470 ) {
            let categorydropdown = null;
            if( isCategoriesDropdownOpen ) {
                categorydropdown = (
                    <ClickOutside
                        onClickOutside={( ev ) => {
                            setCategoriesDropdownOpen(false)
                        }}
                        className="drowdownlist"
                    >
                        <div >
                            {
                                categories.map(( category ) => {
                                    return (
                                        <div
                                            class="language"
                                            onclick={()=>{
                                                let titleelem = document.querySelector( "#" + category.name );
                                                titleelem && titleelem.scrollIntoView();
                                            }}
                                            key={category.id}
                                        >
                                            {category.name}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </ClickOutside>
                )
            }
            categoryselector = (
                <div class="dropdown" onclick={() => setCategoriesDropdownOpen( !isCategoriesDropdownOpen ) }>
                    <div class="currentlanguage">
                        Categories
                    </div>
                    {categorydropdown}
                </div>
            )
        }
    }
	return (
		<div class="topbar">
			<img
                src="../assets/logo.png"
                class="logo"
            />
            <div class="links">
                { categoryselector }
            </div>
            <div class="dropdown" onclick={() => setDropdownstate( !isDropdownOpen ) }>
                <div class="currentlanguage">
                    {currentLanguage.name}
                </div>
                {dropdown}
            </div>
		</div>
	);
}

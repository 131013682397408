import { useEffect, useRef } from 'preact/hooks';

export default function ClickOutside({ children, onClickOutside, className }) {
    const container = useRef(null);
    useEffect(() => {
        function clickOutsideListener( ev ) {
            if( ev && container && container.current && !container.current.contains( ev.target ) ) {
                onClickOutside( ev );
            }
        }
        window.addEventListener( "click", clickOutsideListener );
        return () => {
            window.removeEventListener( "click", clickOutsideListener )
        }
    },[])
    return(
        <div class={className} ref={container} >
            {children}
        </div>
    )
}